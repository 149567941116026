/* Hide Arrows From Input Number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.w-10 {
  width: 10%;
}

.w-40 {
  width: 40%;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.agreementBtn:focus {
  box-shadow: none;
}

.was-validated .custom-select:valid,
.was-validated .form-control:valid {
  background-image: none;
  padding: 0.375rem 0.75rem;
}
