/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .wide-page-image {
    min-width: 260px;
    min-height: 78px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .wide-page-image {
    width: 510px;
    height: 153px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .wide-page-image {
    width: 690px;
    height: 207px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .wide-page-image {
    width: 930px;
    height: 279px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .wide-page-image {
    width: 1110px;
    height: 333px;
  }
}
