/* .min-w-12rem {
  min-width: 100%;
} */

.card-image-inventory {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
  flex-shrink: 0;
  width: 100%;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .card-image-container {
    min-width: 258px;
    min-height: 194px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .card-image-container {
    width: 508px;
    height: 381px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .card-image-container {
    width: 208px;
    height: 156px;
  }
  .min-w-custom {
    min-width: 210px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .card-image-container {
    width: 287.98px;
    height: 215.98px;
  }
  .min-w-custom {
    min-width: 289.98px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .card-image-container {
    width: 348px;
    height: 261px;
  }
  .min-w-custom {
    min-width: 350px;
  }
}
