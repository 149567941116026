/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .truck-image {
    height: calc(81vw / 1.3333);
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .truck-image {
    width: 510px;
    height: 382.5px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .truck-image {
    width: 690px;
    height: 517.5px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .truck-image {
    width: 450px;
    height: 337.5px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .truck-image {
    width: 540px;
    height: 405px;
  }
}
